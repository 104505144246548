<template>
  <v-text-field
    ref="phoneTextField"
    v-model="localValue"
    v-mask="phoneMask"
    v-bind="{ rules: innerRules, validateOn: 'invalid-input', ...customProps, ...$attrs }"
    type="tel"
    inputmode="tel"
    :label="label"
    :error-messages="errorMessage"
    @focus="handleFocus"
    @blur="handleBlur"
    @click:control="phoneTextField?.resetValidation()"
  >
    <template
      v-if="$slots.append"
      #append
    >
      <slot name="append" />
    </template>
  </v-text-field>
</template>

<script lang="ts" setup>
import { useVModel } from '@vueuse/core'
import type { MaskOptions } from 'maska'
import type { VTextField } from 'vuetify/components'

type Emits = {
  (e: 'update:modelValue', v: string): void
}

type Props = {
  modelValue?: string
  label?: string
  customProps?: Record<string, unknown>
  errorMessage?: readonly string[] | string
  baseValidation?: boolean
  baseValidationErrorMessage?: string
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: '',
  label: 'Номер телефона',
  customProps: () => ({}),
  errorMessage: () => ([] as string[]),
  baseValidation: false,
  baseValidationErrorMessage: 'Укажите номер в формате +7 9XX XXX XX XX'
})
const emits = defineEmits<Emits>()
const phoneTextField = ref<VTextField & { isFocused: boolean } | null>(null)

const phonePrefix = '+7 9'
const phoneMask: MaskOptions = {
  mask: '+7 &## ###-##-##',
  tokens: {
    '&': { pattern: /9/ },
    '#': { pattern: /\d/ }
  }
}

const localValue = useVModel(props, 'modelValue', emits)

const innerRules = computed<ValidationRule[]>(() => {
  return props.baseValidation
    ? [
      (v?: null | string) => {
        if (!v) {
          return props.baseValidationErrorMessage
        }

        return v.length === phoneMask.mask?.length || props.baseValidationErrorMessage
      }
    ]
    : []
})

watch(localValue, (val) => {
  if (!val?.startsWith(phonePrefix) && phoneTextField.value?.isFocused) {
    nextTick(() => {
      localValue.value = phonePrefix
    })
  }
})

function handleFocus() {
  if (!localValue.value?.startsWith(phonePrefix) || localValue.value?.length <= phonePrefix.length) {
    localValue.value = phonePrefix
  }
}

function handleBlur() {
  if (localValue.value?.length <= phonePrefix.length) {
    localValue.value = ''
  }
}
</script>
