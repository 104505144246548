<template>
  <v-bottom-sheet v-model="isShowDialog">
    <v-card>
      <div class="pa-4 text-h6">
        Заказать консультацию
      </div>
      <v-divider />
      <v-form
        v-model="isValidForm"
        validate-on="submit"
        @submit.prevent="handleClickSend"
      >
        <div class="pa-6 mb-2">
          <v-select
            v-if="!variantRequestType"
            v-model="requestType"
            label="От кого заявка"
            item-title="label"
            item-value="value"
            density="comfortable"
            class="mb-4"
            :items="CONTACT_TYPES"
          />

          <v-expand-transition>
            <div v-show="isShowClinicsField">
              <v-text-field
                v-model="townName"
                label="Город"
                density="comfortable"
                validate-on="blur"
                class="pb-4"
                :rules="townNameRules"
                @click:control="townTextField?.resetValidation()"
              />
            </div>
          </v-expand-transition>
          <v-expand-transition>
            <div v-show="isShowClinicsField">
              <v-text-field
                v-if="isShowClinicsField"
                v-model="clinicTitle"
                label="Название клиники"
                density="comfortable"
                validate-on="blur"
                class="pb-4"
                :rules="clinicTitleRules"
                @click:control="clinicTextField?.resetValidation()"
              />
            </div>
          </v-expand-transition>

          <PhoneInput
            v-model="phoneNumber"
            label="Номер телефона"
            placeholder="+7 9XX XXX XX XX"
            density="comfortable"
            validate-on="blur"
            :rules="[
              (value: string) => value.length === FORMATED_PHONE_LENGTH || 'Укажите номер в формате +7 9XX XXX XX XX'
            ]"
            active
            persistent-placeholder
            @click:control="textField?.resetValidation()"
          />

          <v-btn
            min-width="100%"
            type="submit"
            text="Жду звонка"
            class="mt-1 mb-6"
            :loading="formLoading"
          />

          <div class="text-center text-caption text-ui-kit-text-secondary">
            Нажимая кнопку «Жду звонка», вы&nbsp;даёте согласие на&nbsp;<nuxt-link
              target="_blank"
              class="text-decoration-none text-primary"
              :href="privacyPolicyLink"
              external
            >обработку&nbsp;персональных&nbsp;данных</nuxt-link>
          </div>
        </div>
      </v-form>
    </v-card>
  </v-bottom-sheet>
  <ContactFormFinalScreens
    v-model="isShowFinalScreens"
    :is-show-success-screen="isShowSuccessScreen"
  />
</template>

<script setup lang="ts">
import { FORMATED_PHONE_LENGTH, CONTACT_TYPES } from '~/components/ContactForm/constants'
import { useContactForm } from '~/components/ContactForm/composables/useContactForm'
import type { ContactFormRequest } from '~/enums'

type Props = {
  modelValue?: boolean
  variantRequestType?: ContactFormRequest
}

const props = defineProps<Props>()

const isShowDialog = useVModel(props, 'modelValue')

const {
  isValidForm,
  formLoading,
  handleClickSend,
  privacyPolicyLink,
  phoneNumber,
  textField,
  isShowSuccessScreen,
  isShowFinalScreens,
  townName,
  clinicTitle,
  isShowClinicsField,
  townNameRules,
  clinicTitleRules,
  townTextField,
  clinicTextField,
  requestType
} = useContactForm({
  isShowDialog,
  propsRequestType: props.variantRequestType
})
</script>
