<template>
  <v-dialog
    v-model="isShowDialog"
    width="456"
  >
    <v-card>
      <AppToolbar
        title="Заказать консультацию"
        close-to
        @click:close="isShowDialog = false"
      />
      <v-form
        v-model="isValidForm"
        validate-on="submit"
        @submit.prevent="handleClickSend"
      >
        <div class="pa-6 mb-2">
          <v-radio-group
            v-if="!variantRequestType"
            v-model="requestType"
            inline
          >
            <v-radio
              v-for="(buttonRequest, index) in CONTACT_TYPES"
              :key="`radio-${index}`"
              class="mr-4"
              color="primary"
              :label="buttonRequest.label"
              :value="buttonRequest.value"
            />
          </v-radio-group>

          <v-expand-transition>
            <div v-show="isShowClinicsField">
              <v-text-field
                ref="townTextField"
                v-model="townName"
                label="Город"
                density="comfortable"
                validate-on="blur"
                class="pb-4"
                :rules="townNameRules"
                @click:control="townTextField?.resetValidation()"
              />
            </div>
          </v-expand-transition>
          <v-expand-transition>
            <div v-show="isShowClinicsField">
              <v-text-field
                ref="clinicTextField"
                v-model="clinicTitle"
                label="Название клиники"
                density="comfortable"
                validate-on="blur"
                class="pb-4"
                :rules="clinicTitleRules"
                @click:control="clinicTextField?.resetValidation()"
              />
            </div>
          </v-expand-transition>

          <PhoneInput
            v-model="phoneNumber"
            class="mb-1"
            label="Номер телефона"
            placeholder="+7 9XX XXX XX XX"
            density="comfortable"
            validate-on="blur"
            :rules="phoneNumberRules"
            persistent-placeholder
          />

          Нажимая кнопку «Жду звонка», вы&nbsp;даёте согласие на&nbsp;<nuxt-link
            target="_blank"
            class="text-decoration-none text-primary"
            :href="privacyPolicyLink"
            external
          >обработку персональных данных</nuxt-link>
        </div>
        <div class="d-flex pb-6 px-6">
          <v-spacer />
          <v-btn
            text="Отменить"
            color="bg-gray-0"
            class="mr-4"
            @click="isShowDialog = false"
          />
          <v-btn
            type="submit"
            text="Жду звонка"
            :loading="formLoading"
          />
        </div>
      </v-form>
    </v-card>
  </v-dialog>
  <ContactFormFinalScreens
    v-model="isShowFinalScreens"
    :is-show-success-screen="isShowSuccessScreen"
  />
</template>

<script setup lang="ts">
import { useContactForm } from '~/components/ContactForm/composables/useContactForm'
import { CONTACT_TYPES } from '~/components/ContactForm/constants'
import type { ContactFormRequest } from '~/enums'

type Props = {
  modelValue?: boolean
  variantRequestType?: ContactFormRequest
}

const props = defineProps<Props>()

const isShowDialog = useVModel(props, 'modelValue')

const {
  isShowSuccessScreen,
  isShowFinalScreens,
  privacyPolicyLink,
  isValidForm,
  townTextField,
  clinicTextField,
  handleClickSend,
  formLoading,
  phoneNumber,
  townName,
  clinicTitle,
  isShowClinicsField,
  requestType,
  townNameRules,
  clinicTitleRules,
  phoneNumberRules
} = useContactForm({
  isShowDialog,
  propsRequestType: props.variantRequestType
})
</script>

<style lang="scss" scoped>
.contact-form-dialog-button {
  border-radius: 8px;
  font-size: 20px;
}
</style>
