import type { ContactFormRequest } from '~/enums'

type SaveClinicContact = {
  contactType: ContactFormRequest.ConnectClinic
  mobilePhone: string
  town: string
  clinic: string
}

type SaveInsuranceContact = {
  contactType: ContactFormRequest.ConnectInsurance
  mobilePhone: string
}

type SaveServiceContact = {
  contactType: ContactFormRequest.ConnectService
  mobilePhone: string
}

type SaveContactPayload = SaveServiceContact | SaveInsuranceContact | SaveClinicContact

export type SaveContactErrorDto = {
  requestType?: string[]
  mobilePhone?: string[]
  detail?: string
}

export const saveContactApi = {
  saveContact(payload: SaveContactPayload) {
    return $apiFetch<SaveContactErrorDto>('/save_contact/', {
      body: payload,
      method: 'POST'
    })
  }
}
