import type { SubmitEventPromise } from 'vuetify'
import type { VTextField } from 'vuetify/components/VTextField'
import type { Ref } from 'vue'
import { saveContactApi } from '~/components/ContactForm/api'
import { ContactFormRequest } from '~/enums'
import { FORMATED_PHONE_LENGTH, MAX_CLINIC_TITLE_LENGTH, MAX_TOWN_NAME_LENGTH } from '~/components/ContactForm/constants'

export const useContactForm = (payload?: { isShowDialog?: Ref<boolean>, propsRequestType?: ContactFormRequest }) => {
  const textField = ref<VTextField | null>(null)
  const clinicTextField = ref<VTextField | null>(null)
  const townTextField = ref<VTextField | null>(null)
  const isValidForm = ref(true)
  const formLoading = ref(false)
  const privacyPolicyLink =
        useRuntimeConfig().public.mfUrl + '/static/dms/pdf/personal_data_processing_policy.pdf'
  const phoneNumber = ref('')
  const townName = ref('')
  const clinicTitle = ref('')
  const requestType = ref<ContactFormRequest>(payload?.propsRequestType ?? ContactFormRequest.ConnectClinic)
  const isShowSuccessScreen = ref(false)
  const isShowFinalScreens = ref(false)

  const phoneNumberRules = [
    (value: string) => value.length === FORMATED_PHONE_LENGTH || 'Укажите номер в формате +7 9XX XXX XX XX'
  ]
  const townNameRules = [
    (value: string) => value.length <= MAX_TOWN_NAME_LENGTH || `Не больше ${MAX_TOWN_NAME_LENGTH} символов`
  ]
  const clinicTitleRules = [
    (value: string) => value.length <= MAX_CLINIC_TITLE_LENGTH || `Не больше ${MAX_CLINIC_TITLE_LENGTH} символов`
  ]

  const isShowClinicsField = computed(() => {
    return requestType.value === ContactFormRequest.ConnectClinic
  })

  const showSuccessMessage = () => {
    isShowSuccessScreen.value = true
    isShowFinalScreens.value = true
  }

  const showErrorMessage = () => {
    isShowSuccessScreen.value = false
    isShowFinalScreens.value = true
  }

  const handleClickSend = async (event: SubmitEventPromise) => {
    formLoading.value = true

    await event

    if (!isValidForm.value) {
      formLoading.value = false

      return
    }

    try {
      switch (requestType.value) {
        case ContactFormRequest.ConnectClinic:
          await saveContactApi.saveContact({
            mobilePhone: getOnlyNumber(phoneNumber.value),
            town: townName.value,
            clinic: clinicTitle.value,
            contactType: ContactFormRequest.ConnectClinic
          })

          break
        case ContactFormRequest.ConnectInsurance:
          await saveContactApi.saveContact({
            mobilePhone: getOnlyNumber(phoneNumber.value),
            contactType: ContactFormRequest.ConnectInsurance
          })

          break
        case ContactFormRequest.ConnectService:
          await saveContactApi.saveContact({
            mobilePhone: getOnlyNumber(phoneNumber.value),
            contactType: ContactFormRequest.ConnectService
          })

          break
      }

      showSuccessMessage()
    } catch {
      showErrorMessage()
    } finally {
      formLoading.value = false

      if (payload?.isShowDialog) {
        payload.isShowDialog.value = false
      }

      phoneNumber.value = ''
    }
  }

  return {
    clinicTextField,
    townTextField,
    isValidForm,
    formLoading,
    privacyPolicyLink,
    phoneNumber,
    isShowSuccessScreen,
    isShowFinalScreens,
    requestType,
    townName,
    clinicTitle,
    isShowClinicsField,
    phoneNumberRules,
    townNameRules,
    clinicTitleRules,
    textField,
    handleClickSend
  }
}
